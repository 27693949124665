import type {
  ChangeEventHandler,
  FocusEventHandler,
  FC,
  KeyboardEventHandler,
  MutableRefObject,
  ReactNode,
} from 'react'
import React, { useState, useEffect } from 'react'
import { Listbox } from '@headlessui/react'
import prefecture from '~/constants/prefecture'

export type InputProps = {
  id?: string
  type: string
  name?: string
  value?: string | number
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  hasLabel?: boolean
  pattern?: string
  title?: string
  inputMode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined
  autoComplete?: string
  autoFocus?: boolean
  step?: number
  min?: number
  max?: number
  defaultValue?: string | number
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  labelClassName?: string
  inputClassName?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  id,
  type,
  name,
  value = undefined,
  label = undefined,
  placeholder = undefined,
  pattern = undefined,
  title = undefined,
  inputMode = undefined,
  autoComplete = undefined,
  autoFocus = false,
  required = false,
  disabled = false,
  hasLabel = true,
  step = undefined,
  min = undefined,
  max = undefined,
  defaultValue = undefined,
  onChange = undefined,
  onKeyDown = undefined,
  onFocus = undefined,
  labelClassName = '',
  inputClassName = '',
}, ref) => {
  return (
    <React.Fragment key={defaultValue}>
      {hasLabel &&
        <label
          htmlFor={id}
          className={`block text-gray-700 text-sm font-bold mb-2 ${labelClassName}`}>
          {label}
        </label>
      }
      <input
        ref={ref}
        id={id}
        type={type}
        className={
          `appearance-none border border-gray-700 rounded w-full py-2 px-3
          text-gray-700 leading-tight
          disabled:opacity-40
          ${inputClassName}`
        }
        placeholder={placeholder}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        pattern={pattern}
        title={title}
        inputMode={inputMode}
        step={step}
        min={min}
        max={max}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    </React.Fragment>
  )
})

export type EmailInputProps = Omit<InputProps, "type">

export const EmailInput: FC<EmailInputProps> = props =>
  <Input type="email" inputMode="email" {...props} />

export type TextInputProps = Omit<InputProps, "type"> & {
  forwardedRef?: MutableRefObject<HTMLInputElement | null>
}

export const TextInput: FC<TextInputProps> = props => {
  const { forwardedRef, ...rest } = props
  return (
    <Input type="text" inputMode="text" ref={forwardedRef} {...rest} />
  )
}

export type URLInputProps = Omit<InputProps, "type"> & {
  forwardedRef?: MutableRefObject<HTMLInputElement | null>
}

export const URLInput: FC<URLInputProps> = props => {
  const { forwardedRef, ...rest } = props
  return (
    <Input type="url" inputMode="url" ref={forwardedRef} {...rest} />
  )
}

export type TelInputProps = Omit<InputProps, "type">

export const TelInput: FC<TelInputProps> = props =>
  <Input type="tel" inputMode="tel" autoComplete="tel-local" {...props} />

export type DatetimeInputProps = Omit<InputProps, "type">

export const DatetimeInput: FC<DatetimeInputProps> = props =>
  <Input type="datetime-local" {...props} />

export type YearInputProps = Omit<InputProps, "type">

export const YearInput: FC<YearInputProps> = props =>
  <Input type="number" inputMode="numeric" {...props} />

export type PostalCodeInputProps = Omit<InputProps, "type">

export const PostalCodeInput: FC<PostalCodeInputProps> = props =>
  <Input type="tel" inputMode="numeric" autoComplete="postal-code" {...props} />

export type FileInputProps = Omit<InputProps, "type">

export const FileInput: FC<FileInputProps> = props =>
  <Input type="file" {...props} />


export type CheckBoxInputProps = {
  id?: string
  name?: string
  className?: string
  value?: string
  label?: string | ReactNode
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  required?: boolean
  defaultChecked?: boolean
  autoFocus?: boolean
}

export const CheckBoxInput: FC<CheckBoxInputProps> = ({
  id = undefined,
  name = undefined,
  className = '',
  value = undefined,
  label = undefined,
  disabled = false,
  onChange = undefined,
  onFocus = undefined,
  required = false,
  defaultChecked = false,
  autoFocus = false,
}) => (
  <div className={`flex items-center ${className}`}>
    <input
      type="checkbox"
      className={`${label ? 'mr-1' : ''} w-4 h-4`}
      name={name}
      value={value}
      id={id}
      defaultChecked={defaultChecked}
      required={required}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
      autoFocus={autoFocus}
    />
    {label &&
      <label htmlFor={id} className="mr-4">{label}</label>
    }
  </div>
)

export type RadioInputProps = {
  id: string
  name: string
  value: string
  label: string
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  required?: boolean
  defaultChecked?: boolean
  autoFocus?: boolean
}

export const RadioInput: FC<RadioInputProps> = ({
  id,
  name,
  value,
  label,
  disabled = false,
  onChange = undefined,
  onFocus = undefined,
  required = false,
  defaultChecked = false,
  autoFocus = false,
}) => (
  <>
    <input
      type="radio"
      className="mr-1"
      name={name}
      value={value}
      id={id}
      defaultChecked={defaultChecked}
      required={required}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
      autoFocus={autoFocus}
    />
    <label htmlFor={id} className="mr-4">{label}</label>
  </>
)

export type PreviewImageProps = {
  file: File,
  fileKey: string,
  onClickDeleteBtn: (key: string) => void,
}

export const PreviewImage: FC<PreviewImageProps> = React.memo(({
  file,
  fileKey,
  onClickDeleteBtn,
}) => {
  const [src, setSrc] = useState<string>('')
  useEffect(() => {
    const objectURL = URL.createObjectURL(file)
    setSrc(objectURL)
    return () => URL.revokeObjectURL(objectURL)
  }, [])
  return (
    <div className="flex flex-col flex-none bg-white">
      <img
        src={src}
        className="h-24 border border-gray-500"
        draggable={false}
      />
      <button
        className="w-full border border-gray-500"
        onClick={() => onClickDeleteBtn(fileKey)}
      >削除</button>
    </div>
  )
})

export type SelectPrefectureProps = {
  name: string
  defaultValue?: string
  disabled?: boolean
  label?: string
  onChange?: (value: string) => void
}

export const SelectPrefecture: FC<SelectPrefectureProps> = ({
  name,
  defaultValue = '23',
  disabled = false,
  label = '都道府県',
  onChange,
}) => {
  return (
    <SelectBox
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      options={Object.entries(prefecture)}
      label={label}
      onChange={onChange}
    />
  )
}

export type SelectBoxProps = {
  name?: string
  label: string
  options: Array<[string, string]>
  defaultValue?: string
  disabled?: boolean
  onChange?: (value: string) => void
}

export const SelectBox: FC<SelectBoxProps> = ({
  name,
  label,
  options,
  defaultValue = '',
  disabled = false,
  onChange = undefined,
}) => {
  const [selected, setValue] = useState<string>(defaultValue)
  useEffect(() => {
    if (options[0]) {
      setValue(options[0][0])
    }
  }, [])
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  const retrieveKey = (selected: string | undefined): string => {
    if (!selected) return ''
    const found = options.find(([key, _]) => selected === key)
    return found ? found[1] : ''
  }
  return (
    <div className="w-full">
      <span className="block text-gray-700 text-sm font-bold mb-2" >{label}</span>
      <div className="relative border border-gray-700 rounded w-full text-gray-700 leading-tight">
        <Listbox
          value={selected}
          onChange={value => {
            setValue(value)
            if (onChange) onChange(value)
          }}
          name={name}
          disabled={disabled}
        >
          <Listbox.Button className="block w-full flex justify-between py-2 px-3">
            <span>{retrieveKey(selected)}</span>
            <span className="inline-block">▼</span>
          </Listbox.Button>
          <Listbox.Options
            className="absolute z-10 inset-x-0 text-center border bg-white max-h-64 p-2 inline-block cursor-pointer overflow-y-scroll flex flex-wrap justify-center"
          >
            {options.map(([key, value]) => (
              <Listbox.Option
                key={key}
                value={key}
                className="hover:bg-primary hover:text-white basis-full flex-none"
              >
                {value}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  )
}

export type HeaderSelectOptions = {
  [key: string]: {
    option: string | ReactNode,
    selectedOption?: string | ReactNode
  }
}

export type HeaderSelectBoxProps = {
  name?: string
  className?: string
  isVertical: boolean
  arrow?: string | ReactNode
  defaultKey?: string
  label: string
  options: HeaderSelectOptions
  suffix?: string
  disabled?: boolean
  form?: string
  onChange: (value: string | undefined) => void
  hasUnselected?: boolean
}

export const HeaderSelectBox: FC<HeaderSelectBoxProps> = ({
  name,
  className,
  isVertical,
  arrow,
  defaultKey,
  label,
  options,
  suffix = '',
  disabled = false,
  form = undefined,
  onChange,
  hasUnselected = true,
}) => {
  const unselectedKey = 'UNSELECTED'
  const unselectedLabel = '未選択'
  const [selectedKey, setSelectedKey] = useState<string>(
    defaultKey && defaultKey.trim() !== '' ? defaultKey.trim() : unselectedKey
  )
  useEffect(() => {
    setSelectedKey(defaultKey && defaultKey.trim() !== '' ? defaultKey.trim() : unselectedKey)
  }, [defaultKey])
  const retrieve = (key: string, selected: boolean = false): string | ReactNode => {
    const found = key in options ? options[key] : undefined
    return found ? selected ? found.selectedOption ?? found.option : found.option : ''
  }
  return (
    <div className={`${className ?? ""} relative w-full text-gray-700 ${disabled ? 'opacity-40' : ''}`}>
      <Listbox
        form={form}
        value={selectedKey}
        name={name}
        onChange={value => {
          setSelectedKey(value)
          if (onChange) onChange(value === unselectedKey ? undefined : value)
        }}
        disabled={disabled}
      >
        <Listbox.Button
          className={`w-full flex ${isVertical ? "py-2 md:py-4 pl-4 pr-2 justify-between items-center" : "justify-center"}`}
        >
          {selectedKey === unselectedKey ?
            <span className="text-center">{label}<span className="text-xs">で選ぶ</span></span>
            : retrieve(selectedKey, true)
          }{selectedKey !== unselectedKey && suffix}
          <span className="inline-block">{arrow}</span>
        </Listbox.Button>
        <Listbox.Options
          className="absolute z-10 w-full text-center border bg-white max-h-64 p-2 inline-block cursor-pointer overflow-y-scroll flex flex-wrap justify-center text-gray-700 font-normal">
          {hasUnselected &&
            <Listbox.Option
              key={unselectedKey}
              value={unselectedKey}
              className="hover:bg-primary hover:text-white basis-full flex-none"
            >{unselectedLabel}</Listbox.Option>
          }
          {Object.entries(options).map(([key, { option, selectedOption }]) => (
            <Listbox.Option
              key={key}
              value={key}
              className="hover:bg-primary hover:text-white basis-full flex-none"
            >
              {option}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

export type TextAreaProps = {
  id?: string
  name?: string
  cols?: number
  label?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  hasLabel?: boolean
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
  autoComplete?: string
  autoFocus?: boolean
  className?: string
}

export const TextArea: FC<TextAreaProps> = ({
  id = undefined,
  name = undefined,
  value = undefined,
  defaultValue = undefined,
  cols = undefined,
  label = undefined,
  placeholder = undefined,
  onChange = undefined,
  onFocus = undefined,
  autoComplete = undefined,
  autoFocus = false,
  required = false,
  disabled = false,
  hasLabel = true,
  className = '',
}) =>
  <div className="h-full w-full flex flex-col">
    {hasLabel &&
      <label
        htmlFor={id}
        className="block text-gray-700 text-sm font-bold mb-2 h-fit">
        {label}
      </label>
    }
    <textarea
      id={id}
      className={
        `appearance-none border border-gray-700 rounded w-full py-2 px-3 text-gray-700 leading-tight grow ${className}`
      }
      placeholder={placeholder}
      name={name}
      cols={cols}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onFocus={onFocus}
      required={required}
      disabled={disabled}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
    />
  </div>

